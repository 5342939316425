import React from 'react'
import Divider from '@components/Divider'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        #eeeeee 32.5%,
        #bbbbbb 50.5%)
    `,
    // background: t => `
    //   linear-gradient(
    //     45deg,
    //     ${t.colors.alpha} 32.5%,
    //     ${t.colors.beta} 50.5%)
    // `,
    color: `Omega`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaLight`,
    fontWeight: `normal`,
    maxWidth: [`full`] //, `full`, `2/3`]
  }
}
// const styles = {
//   author: {
//     display: `inline-block`,
//     color: `alpha`
//   },
//   occupation: {
//     mb: 4
//   },
//   specialty: {
//     color: `text`,
//     mb: 4
//   }
// }

export default () => (
  <>
    <Divider space={3} />

    <Heading variant='h1' sx={styles.heading}>
      ReCharge gifting, no problem
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
      <p>Churn is a major problem, it costs 5 to 7 times more to acquire a new customer compared to retaining an existing one.</p>
      {/* <p>Upselling subscriptions is hard. Your customers may never visit your website again, even though it can generate 10-30% more revenue.</p> */}
      <p>We help you to discover when and why your customers cancel their subscriptions and put together an automated gifting strategy to reduce your churn rate.</p>
      {/* <p>We help solve these problems.</p> */}
    </Heading>

    {/*<Heading variant='h1' sx={styles.heading}>
      Viadog Subscriptions
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
      <p>
        We've made it as simple as possible to get more value out of your
        Shopify and ReCharge subscription orders.
      </p>
      <p>Increase revenue and reduce churn with our set and forget tools.</p>
    </Heading>*/}

    <Divider space={4} />

    {/*<Heading variant='h1'>
      Hi, I'm <Text sx={styles.author}>Jane Smith</Text>.
    </Heading>
    <Heading variant='h1' sx={styles.occupation}>
      UX/UI Designer
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      Specialized in Gatsby and React
    </Heading>
    <Box variant='buttons.group'>
      <Button as={Link} to='/contact'>
        Contact Me
      </Button>
      <Button variant='white' as={Link} to='/about'>
        About Me
      </Button>
  </Box>*/}
  </>
)
