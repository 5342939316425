import React from 'react'
import HeroWide from '@widgets/HeroWide'
import ContentIndex from './Hero.Content'
import ContentSubscriptions from './HeroSubscriptions.Content'
import Overlay from './Hero.Overlay'
import Photo from './Hero.Photo'

const styles = {
  wrapper: {
    pb: 0,
    // maxWidth: '900px'
    maxWidth: '100%'
  }
}

const Hero = props => {
  const {
    location: { pathname },
    pageContext: { basePath }
  } = props

  if (pathname === '/') {
    return (
      <HeroWide.Wrapper sx={styles.wrapper}>
        <HeroWide.LeftColumn>
          <ContentIndex />
        </HeroWide.LeftColumn>
        {/*<HeroWide.RightColumn>
          <Photo />
        </HeroWide.RightColumn>*/}
        {/*<HeroWide.Overlay>
          <Overlay />
        </HeroWide.Overlay>*/}
      </HeroWide.Wrapper>
    )
  } else if (pathname.includes('/subscriptions')) {
    return (
      <HeroWide.Wrapper sx={styles.wrapper}>
        <HeroWide.LeftColumn>
          <ContentSubscriptions />
        </HeroWide.LeftColumn>
        {/*<HeroWide.RightColumn>
          <Photo />
        </HeroWide.RightColumn>*/}
        {/*<HeroWide.Overlay>
          <Overlay />
        </HeroWide.Overlay>*/}
      </HeroWide.Wrapper>
    )
  } else {
    return null
  }
}

export default Hero
