import React from 'react'
import Divider from '@components/Divider'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        #eeeeee 32.5%,
        #bbbbbb 50.5%)
    `,
    // background: t => `
    //   linear-gradient(
    //     45deg,
    //     ${t.colors.alpha} 32.5%,
    //     ${t.colors.beta} 50.5%)
    // `,
    color: `Omega`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaLight`,
    fontWeight: `normal`,
    maxWidth: [`full`] //, `full`, `2/3`]
  }
}
// const styles = {
//   author: {
//     display: `inline-block`,
//     color: `alpha`
//   },
//   occupation: {
//     mb: 4
//   },
//   specialty: {
//     color: `text`,
//     mb: 4
//   }
// }

export default () => (
  <>
    <Divider space={3} />
    <Heading variant='h1' sx={styles.heading}>
      We build internal tools for startups and small businesses
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
      We work behind the scenes to help automate manual tasks and get different
      systems talking to each other, increasing efficiency and reducing overall
      cost.
    </Heading>
    <Divider space={4} />
    {/*<Heading variant='h1'>
      Hi, I'm <Text sx={styles.author}>Jane Smith</Text>.
    </Heading>
    <Heading variant='h1' sx={styles.occupation}>
      UX/UI Designer
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      Specialized in Gatsby and React
    </Heading>
    <Box variant='buttons.group'>
      <Button as={Link} to='/contact'>
        Contact Me
      </Button>
      <Button variant='white' as={Link} to='/about'>
        About Me
      </Button>
  </Box>*/}
  </>
)
